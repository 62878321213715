@font-face{
  font-family: "AktivGrotesk-Bold";
  src:local("AktivGrotesk-Bold"),url("./assets/fonts/AktivGrotesk-Bold.ttf") format(truetype);
}

@font-face {
  font-family: "AktivGrotesk-Medium";
  src:local("AktivGrotesk-Medium"),url("./assets/fonts/AktivGrotesk-Medium.ttf") format(truetype);
}

@font-face {
  font-family: "AktivGrotesk-Regular";
  src:local("AktivGrotesk-Regular"),url("./assets/fonts/AktivGrotesk-Regular.ttf") format(truetype);
}

@font-face {
  font-family: "AktivGrotesk-Hairline";
  src: local("AktivGrotesk-Hairline"), url("./assets/fonts/AktivGrotesk-Hairline.ttf") format(truetype);
}

@font-face {
  font-family: "AktivGrotesk-Light";
  src: local("AktivGrotesk-Light"),url("./assets/fonts/AktivGrotesk-Light.ttf") format(truetype); 
}

body {
  margin: 0;
  font-family: 'AktivGrotesk-Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Aileron';
}
