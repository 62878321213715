.sections-amenidades {
    display: flex;
    width: 100%;
}
  
/* Left */
  
@media(prefers-reduced-motion) {
    .hidden-left-amenidades {
      transition: none;
    }
}
  
.hidden-left-amenidades {
    width: 100%;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-80%);
    transition: all 1s;
}
  
/* Show */
  
.show-amenidades {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}
